<template>
    <div class="adindex">
        <div class="adtit acea-row row-between-wrapper">
            <div>产品&广告图
                <span>Product & Advertising map</span>
            </div>
        </div>
        <div class="ad-img-box ">
            <div class="img-box" v-for="(item,index) in adList" :key="index" >
                <VanImage width="100%" height="100%" :src="item.image" ></VanImage>
            </div>
        </div>
         <div class="Loading" v-if="loading">
            <Loading size="24px">加载中...</Loading>
        </div>
        <div class="Loading" v-if="finished">没有更多了~</div>
    </div>
</template>
<script>
import { Tab, Tabs } from 'vant';
import { Image as VanImage } from 'vant';
import { ProductAd } from "@api/tasting";
export default {
    name: "adindex",
    props: {
        goodsList: {
        type: Array,
        default: () => []
        }
    },
    data: function() {
        return {
            adList:[],
            loading:false,
            finished:false,
            page:1,
            limit:8
        };
    },
    components: {
        VanImage, Tab, Tabs
    },
    created(){
        this.id=Number(this.$route.query.id);
    },
    mounted: function() {
        this.getAd();
        var that = this;
        window.addEventListener("scroll", function(event) {
            var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop ) {
                that.Loadmore();
            }
        });
    },
    methods: {
        Loadmore: function() {
            if(this.loading) return;
            if(this.finished) return;
            this.getAd();
        },
        getAd:function(){
            this.loading = true;
            ProductAd({
                page:this.page,
                limit:this.limit,
                id:this.id
            }).then(res=>{
                var listdata = res.data.data;
                listdata.forEach((item)=>{
                    this.adList.push(item);
                })
                this.loading = false;
                this.page++;
                if(listdata.length<5){
                    this.finished = true;
                }
                //this.adList = res.data.data;
            }).catch(err => {
                this.loading = false;
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
    }
}
</script>
<style scoped lang="scss">
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
.adindex{
    .adtit{
        position: relative;
        background-color: #e8e8e8;
        padding: 0.2rem 0.35rem;
        font-weight: 600;
        color: #484444;
        font-size: 0.3rem;
        .adPages{
            position: absolute;
            right: 0.08rem;
            
        }
    }
    .ad-img-box{
        column-count:2;
        column-gap: 1;
        margin: 0.4rem;
        .img-box{
            padding-top: 0.24rem;
        }
    }
    .adPages-bottom{
        text-align: center;
        padding-bottom: 0.2rem;
        font-weight: 600;
        span{
           margin-left: 0.2rem;
        }
    }
}
</style>